/* Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600;700&family=Source+Sans+Pro:wght@400;600;700;900&display=swap');

/* Oswald */
.oswald {
    font-family: 'Oswald', sans-serif;
}

/* Source Sans Pro */
.ssp {
    font-family: 'Source Sans Pro', sans-serif;
}

.header {
    width: 98% !important;
}

body {
    background-color: #00204a;
}

.quat-light {
 background-color: #d9faffb0;
}

.prime-light {
    background-color: #546375;
    color:  #00204a9f;
}